<template>
  <page title="我的推广" >
    
    <list>
        <media-item 
          :image="item.image" 
          :title="item.title"  
          avatar-size="60"
          note="联系电话：18383838383"
          class="flex-1"
          v-for="item in TestData.examines" 
          :key="item.id" 

        >
          <div class="text-gray-light mt-1 text-xs">
            注册日期：2020-10-20
          </div>
          <div class="flex items-center justify-between mt-1"  >
            <div class="">
              <span class="text-xs text-gray-light">累计消费：</span>
              <span class="text-xs text-red-500">¥ </span>
              <span class="text-red-600 font-medium">89.9</span>
            </div>
            <div class="text-gray-light text-xs">
              <span>累计获得佣金：</span>
              <span class="text-green-500">¥392.00</span>
            </div>
          </div>
        </media-item>
      </list>
    <div class="h-12 pl-4 bg-gray-50 relative hairline-top  flex items-center" slot="footer">
      <div class="flex-1 flex items-center ">
        积分额度：<span class="text-lg font-medium text-red-500">3920.00 分</span>
      </div>
    </div>
  </page>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  data(){
    return{
      
    }
  },
  computed: {
    ...mapGetters([
      'TestData'
    ])
  },
  methods:{
    
    goPay(){
      this.$router.push('/paid')
    }
  },
  created(){}
}
</script>

<style lang='stylus'>

</style>